<script>
import moment from 'moment';
import appConfig from "@/app.config";
import { http } from '@/helpers/easyindustriaapi/config';
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";

export default {
  page: {
    title: "Listar Compras",
    meta: [{name: "description", content: appConfig.description}]
  },
  components: { Layout, PageHeader, List },
  data() {
    return {
      loader: {
        get: false
      },
      currentUser: null,
      currentEmpresa: null,
      currentPedido: {
        id: null,
        pessoa_id: null,
        nnf: null,
        tpnf: 0,
        tipopgto_id: null,
        qtde_parcelas: 1,
        historicopdr_id: null,
        tpmovimento_id: null,
        loc_id: null,
      },
      searchCompras: {},
      titleBody: "Listar Compras",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Pedidos",
          href: "/financeiro/pedidos",
          active: true,
        }
      ],
      hideSearches: false,
      listPreCompra: [],
    }
  },
  computed: {},
  created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    this.searchCompras.fromData = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.searchCompras.toData = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.currentPedido.dh_saient = this.searchCompras.toData;
    this.currentPedido.dataemissao = this.searchCompras.toData;
  },
  mounted() {
    this.getData();// Lemvbrar que posso pegar o vetor de produtos pelo metodo get, 
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.$router.go(-1);
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none")
      {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block")
      {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    searchInputs() {
      this.hideSearches = !this.hideSearches;
    },
    async getData() {
      this.onLoader();

      try {
        let toParams = {
          nnf: this.searchCompras.nnf,
          toNNf: this.searchCompras.toNNf,
          fromData: (this.searchCompras.fromData) ? moment(this.searchCompras.fromData).format('yyyy-MM-DD HH:mm:ss') : null,
          toData: (this.searchCompras.toData) ? moment(this.searchCompras.toData).format('yyyy-MM-DD HH:mm:ss') : null,
          destinatario: this.searchCompras.destinatario
        };
        let response = await http.get('/pre-pedido?empresa_id=' + this.currentEmpresa.id, {
          params: toParams
        });

        if (response.status === 200) {
          this.listPreCompra = response.data;
          setTimeout(() => {
            this.offLoader();
          }, 2000);
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.nnf) ? messageErro.pessoa_id : messageErro.tipo_id);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;

          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
        this.loader.get = false;
      }

      this.loader.get = false;
    },
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <button class="btn btn-light" @click="searchInputs()">
                  <span class="fa-sm fa" :class="(hideSearches) ? 'fa-chevron-up' : 'fa-chevron-down'"></span>
                   Busca avançada
                </button>
              </div>
              <div class="col-sm-12 col-md-12 text-md-right">
                <button class="btn btn-secondary" @click="back()">Voltar</button>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="hideSearches">
            <!-- Start Card -->
            <div class="card border border-primary">
              <div class="card-body">
                <h4 class="card-title">Filtros</h4>
                <p class="card-title-desc">Use esses campos para filtrar os registros</p>
                <form class="needs-validation" name="search">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="searchCompras-status">N.º do pedido</label>
                        <div class="row">
                          <div class="col-md-6">
                            <input id="searchCompras-id" v-model="searchCompras.id" type="number"
                              class="form-control" placeholder="ID do Pré-Pedido" />
                          </div>
                          <div class="col-md-6">
                            <input id="searchCompras-nnf" v-model="searchCompras.nnf" type="number"
                              class="form-control" placeholder="Nota Fiscal" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="searchCompras-fromData">Data saída</label>
                        <div class="row">
                          <div class="col-md-6">
                            <input id="searchCompras-fromData" v-model="searchCompras.fromData" type="datetime-local"
                              class="form-control" placeholder="dd/mm/aaaa hh:mm"/>
                          </div>
                          <div class="col-md-6">
                            <input id="searchCompras-toData" v-model="searchCompras.toData" type="datetime-local"
                              class="form-control" placeholder="dd/mm/aaaa hh:mm"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Fornecedor</label>
                        <input id="searchCompras-destinatario" v-model="searchCompras.destinatario" type="text"
                          class="form-control" placeholder="Nome do Fornecedor" />
                      </div>
                    </div>
                  </div>
                  <button @click="getData" class="btn btn-primary" type="button">OK</button>
                </form>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <List :currentEmpresa="currentEmpresa" :listPedidos="listPreCompra" :isBusy="loader.get" />
        </div>
      </div>
    </div>
  </Layout>
</template>